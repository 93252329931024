import React from 'react';
//
import useObjectOfNanPao from '../../hooks/useObjectOfNanPao';
import Dashboard from '../../components/Dashboard';

const HsiTa = () => {
  const { data: objectList } = useObjectOfNanPao(1);

  if (!objectList || objectList.length === 0) {
    return <></>;
  }

  return (
    <Dashboard
      objectList={objectList}
      metricsTypeList={['temperature', 'irr', 'co2', 'plantTree']}
      generationType={'accumulatedKwh'}
    />
  );
};

export default HsiTa;
